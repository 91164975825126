import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import "amfe-flexible/index"
import { DatePicker } from 'vant';
import { Toast } from 'vant';
import 'vant/lib/index.css'
import './assets/main.scss'

createApp(App).use(DatePicker).use(Toast).use(router).mount('#app');
