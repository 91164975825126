import { defineComponent } from 'vue';
export default defineComponent({
  name: "z-meteor",
  props: {
    size: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    right: {
      type: Number,
      default: 0
    },
    delay: {
      type: Number,
      default: 0
    }
  }
});