import * as THREE from 'three';
import TWEEN from '@tweenjs/tween.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { onMounted, ref, nextTick } from 'vue';
// import { userList } from './data';
import http from '../http/request';
import wx from 'weixin-webview-jssdk/index.js';
import { showToast } from 'vant';

type userType = {
  id: string;
  type: number;
  name: string;
  uid: string;
  urlImg: string;
  phi?: number;
  theta?: number;
};
const jump = ref(true);
const url = location.search; //获取url中"?"符后的字串
const urlParams = new URLSearchParams(url);
const memberId = urlParams.get('memberId') || null;
const num = urlParams.get('width');
// 窗口宽度
const width = num ? parseFloat(num) : 375;
// 窗口高度
const height = num ? parseFloat(num) : 375;
const bgIndex = ref(1);
const actType = ref(0);
export function useThree(isload: { show: boolean }) {
  const userData = ref<userType[]>([]);
  const userId = ref('');
  let camera: THREE.PerspectiveCamera;
  let scene: THREE.Scene;
  let group: THREE.Group;
  let renderer: THREE.WebGLRenderer;
  let raycaster: THREE.Raycaster;
  let mouse: THREE.Vector2;
  /** 控制器 */
  let controls: TrackballControls;
  let sprites: THREE.Sprite[] = [];
  let random: number[] = [];

  /** 渲染3D星球 */
  async function render3D() {
    await init();
    await getUserList();

    if (!scene) {
      return;
    }

    const length = userData.value.length;
    random = Array.from({ length }, () => Math.random());

    userData.value.map(async (item, index) => {
      const phi = Math.acos(-1 + (2 * index) / length);
      const theta = Math.sqrt(length * Math.PI) * phi;

      const sprite = renderSprite(item.name, item.urlImg, item.type);
      setTimeout(() => {
        if (sprite) {
          sprite.userData.data = item;
          sprite.scale.set(500, 500, 1);
          sprite.position.setFromSphericalCoords(800, phi, theta);
          group.add(sprite);

          sprites.push(sprite);
        }
      }, 200);
    });
    scene.add(group);

    // 设置渲染区域尺寸
    renderer.setSize(width, height);
    renderer.shadowMap.enabled = false;
    // 设置背景颜色
    renderer.setClearColor('#275986', 0);
    renderer.setPixelRatio(window.devicePixelRatio);
    // body元素中插入canvas对象
    document.getElementById('canvas1')?.appendChild(renderer.domElement);

    controls = new TrackballControls(camera, renderer.domElement);
    controls.rotateSpeed = 0.5; //旋转速度
    controls.minDistance = 500; //最小距离
    controls.maxDistance = 6000; //最大距离
    controls.noZoom = true;
    controls.noPan = true;
    controls.addEventListener('change', render);
    // 监听鼠标点击事件
    document.addEventListener('click', onClick);

    transform();
  }

  /** 点击事件 */
  function onClick(event: any) {
    event.preventDefault();
    /** 计算鼠标位置 */
    mouse.x = (event.clientX / width) * 2 - 1;
    mouse.y = -(event.clientY / height) * 2 + 1;
    // 将鼠标点击位置转换为 Three.js 中的向量
    raycaster.setFromCamera(mouse, camera);

    // 获取所有与射线相交的物体(多个)
    const intersects = raycaster.intersectObjects(scene.children, true);
    if (jump.value) {
      jump.value = false;
      setTimeout(() => {
        jump.value = true;
      }, 400);
    } else return;
    if (intersects.length > 0) {
      // 取第一条数据(前面)
      const obj = intersects[0].object;
      // 判断对象是否为Sprite对象
      if (obj instanceof THREE.Sprite) {
        if (obj.userData.data.type === 0) {
          // userId.value ? wx.miniProgram.navigateTo({ url: `../../pages_sever/mystar/index?id=${obj.userData.data.id}`})  : wx.miniProgram.navigateTo({ url: '../index/login' })
          wx.miniProgram.navigateTo({ url: `../../pages_sever/mystar/index?id=${obj.userData.data.id}` });
        }
        if (obj.userData.data.type === 1) {
          // wx.miniProgram.postMessage({data: {labelId: obj.userData.data.id}})
          // wx.miniProgram.switchTab({ url: `../smallWorld/index` });
          wx.miniProgram.navigateTo({ url: `../../pages_sever/scenario/index?labelId=${obj.userData.data.id}` });
        }
        return;
      }
    }
  }

  /** 开启渲染动画 */
  function startRender() {
    TWEEN.update();
    // 请求再次执行渲染函数render
    requestAnimationFrame(startRender);
    // 更新视图
    controls.update();

    const time = Date.now() / 1000;
    const quaternion = new THREE.Quaternion();
    const x_axis = new THREE.Vector3(1, 0, 0);
    const y_axis = new THREE.Vector3(0, 1, 0);
    camera.position.applyQuaternion(quaternion.setFromAxisAngle(x_axis, -0.001));
    camera.position.applyQuaternion(quaternion.setFromAxisAngle(y_axis, -0.001));
    // 每次绕y轴旋转0.01弧度
    // group.rotateY(0.001);
    // 每次绕x轴旋转0.01弧度
    // group.rotateX(0.001);
    // 执行渲染操作(指定场景、相机作为参数)
    render();
    sprites.forEach((sprite, index) => {
      // 透明度
      const distance = camera.position.distanceTo(sprite.position);
      const opacity = distance > 3000 ? 1 - (distance - 3000) / 1000 : 1;
      sprite.material.opacity = opacity;
      // 放大缩小
      const scale = Math.sin(time + random[index] * 200) * 0.1 + 1.0;
      sprite.scale.set(500 * scale, 500 * scale, 1);
      // 高斯模糊
      if (sprite.material.map) {
        sprite.material.map.needsUpdate = true;
        sprite.material.map.magFilter = THREE.LinearFilter;
      }
    });
  }

  /** 过渡动画 */
  function transform() {
    camera.position.set(0, 0, 30000);
    camera.lookAt(0, 0, 0);

    new TWEEN.Tween(camera.position).to({ x: 0, y: 0, z: 3000 }, 2000).start().easing(TWEEN.Easing.Sinusoidal.InOut);
    setTimeout(() => {
      isload.show = false;
    }, 1500);
  }

  function render() {
    renderer.render(scene, camera);
  }

  /** 初始化 */
  async function init() {
    /** init */
    /** 相机设置 */
    camera = new THREE.PerspectiveCamera(40, width / height, 1, 8000);
    /** 相机设置 */
    // camera.position.set(20000, 20000, 0);
    // camera.position.z = 3000;

    /** 创建场景对象Scene */
    scene = new THREE.Scene();

    /** item群组 */
    group = new THREE.Group();

    /** 渲染器对象 */
    renderer = new THREE.WebGLRenderer({
      antialias: true,
    });

    // 监听鼠标点击事件
    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // /** 光源设置 */
    // const spotLight = new THREE.SpotLight(0xffffff, 1.0);
    // scene.add(spotLight);
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    // directionalLight.castShadow = true;
    // // 点光源
    // const point = new THREE.PointLight(0xffffff);
    // point.position.set(400, 200, 300);
    // scene.add(point);

    // // 环境光
    // const ambient = new THREE.AmbientLight(0x444444);
    // scene.add(ambient);
  }

  /** 获取用户列表 */
  async function getUserList() {
    userId.value = memberId || '';
    const params = {
      menberId: memberId,
      type: memberId ? 1 : 0,
      starType: actType.value || 0,
    };
    const res: any = await http.request({
      url: '/ds-applet/v1/appHomePage/homePlanet',
      method: 'post',
      data: params,
    });
    if (res.code === 200) {
      // console.log(res)
      const list: userType[] = res.data || [];
      userData.value = list.map((item) => ({
        ...item,
        urlImg: `${item.urlImg}?abc=123`,
      }));
    } else showToast(res.message);
  }

  /** 重置 */
  async function reset(type: number) {
    // 清除事件
    controls.removeEventListener('change', render);
    controls.removeEventListener('click', onClick);
    // 删除元素
    document.getElementById('canvas1')?.removeChild(renderer.domElement);
    sprites = [];
    actType.value = type;
    render3D();
  }

  onMounted(async () => {
    await render3D();
    startRender();
  });

  return {
    render3D,
    reset,
  };
}

/**
 * 每个item组合成一张雪碧图
 * @param text 文本内容
 */
function renderSprite(text: string, url: string, type: number) {
  const itemCanvas = createItemCanvas(text, url, type, 400, 400);
  if (itemCanvas) {
    const texture = new THREE.CanvasTexture(itemCanvas);
    texture.generateMipmaps = false;
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.needsUpdate = true;

    const pinMaterial = new THREE.SpriteMaterial({
      map: texture,
      opacity: 0,
      transparent: true,
    });

    const mesh = new THREE.Sprite(pinMaterial);

    return mesh;
  } else {
    return null;
  }
}

/**
 * 创建画布
 * @param text 内容
 * @param width 宽度
 * @param height 高度
 * @returns canvas
 */
function createItemCanvas(text: string, url: string, type: number, width: number, height: number) {
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    /** 绘制item(上面是name,下面是圆圈) */
    ctx.beginPath();

    /** 绘制圆形 */
    drawImage(ctx, url, type, 300, 300);
    // ctx.arc(width / 2, (5 * height) / 8, height / 8, 0, 2 * Math.PI);
    // ctx.fillStyle = '#84f7ea';
    // ctx.fill();

    /** 绘制文字(颜色、字体样式、布局、内容) */
    // 文本填充颜色
    ctx.fillStyle = '#fff';
    // 字体样式设置
    ctx.font = 'bold oblique 46px Arial';
    // 文本上下居中(fillText定义的纵坐标)
    ctx.textBaseline = 'middle';
    // 文本左右居中(fillText定义的横坐标)
    ctx.textAlign = 'center';
    ctx.shadowColor = 'rgba(0, 0, 0, 0.6)';
    ctx.shadowOffsetY = 2;
    ctx.shadowBlur = 4;
    // 文本内容
    ctx.fillText(text, width / 2, (3.25 * height) / 4);

    ctx.closePath();

    return ctx.canvas;
  }
}

function drawImage(ctx: CanvasRenderingContext2D, url: string, type: number, width: number, height: number) {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = url;
  img.onload = () => {
    if (type === 0) {
      ctx.beginPath();
      ctx.arc(220, 180, 120, 0, 2 * Math.PI);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0, img.width, img.height);
    }
    ctx.drawImage(img, 50, 0, width, height);
  };
  img.onerror = (err) => {
    console.log('图像加载失败');
  };
}